// Skeleton.module.scss
@keyframes waveAnimation {
    0% {
        background-position-x: -100%;
    }

    100% {
        background-position-x: 100%;
    }
}

.skeleton {
    display: inline-block;
    height: 34px; // default height
    background-color: #242424;
    background-image: linear-gradient(90deg, rgba(255, 255, 255, 0) 0, rgba(255, 255, 255, 0.2) 20%, rgba(255, 255, 255, 0.5) 60%, rgba(255, 255, 255, 0));
    background-size: 200% 100%;
    animation: waveAnimation 1.6s ease-in-out infinite;
}